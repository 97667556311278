import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Quote } from '@shared/models/quote.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  constructor(private http: HttpClient) {}

  getQuote$(hash: string): Observable<Quote> {
    return this.http.get<Quote>(`${environment.apiEnv}api/booking/${hash}/quote/`);
  }
}
