import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpListResponse } from '@ess-front/shared';
import { RelatedBE } from '@shared/models/itinerary/experience-be.model';
import { Related } from '@shared/models/itinerary/related.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RelatedExperiencesService {
  private apiURL = `${environment.apiEnv}api/v2/`;

  constructor(private httpClient: HttpClient) {}

  public getRelated$(id: number, language: string): Observable<Related[]> {
    const url = `${this.apiURL}experiences/${id}/related/?lan=${language}`;
    return this.httpClient
      .get<HttpListResponse<RelatedBE>>(url)
      .pipe(map(response => response.results.map(this.transformToRelated)));
  }

  private transformToRelated(relatedBE: RelatedBE): Related {
    return {
      icon: relatedBE.icon,
      location: relatedBE.location,
      title: relatedBE.title,
    };
  }
}
