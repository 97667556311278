import {
  ExperienceCategory,
  ExperienceStatus,
  ExperienceStyle,
  ExperienceSubcategory,
  ExperienceType,
} from '@ess-front/shared';
import { Location } from '@shared/models/be-experience.model';

export interface CategoryBE {
  id: number;
  slug: ExperienceCategory;
  title: string;
  type: ExperienceType;
}

export interface SubcategoryBE {
  id: number;
  slug: ExperienceSubcategory;
  title: string;
}

export interface CityBE {
  id: number;
  title: string;
}

export interface GalleryImageBE {
  description: string;
  local_src: string | null;
  title: string;
}

export interface RelatedBE {
  icon: string;
  location: Location;
  title: string;
}

export enum ExperienceParentType {
  Accomodation = 'Accommodation',
  ExperienceModule = 'ExperienceModule',
  Place = 'Place',
}

export interface ExperienceParentBE {
  id?: number;
  type?: ExperienceParentType;
}

export interface ExperienceBE {
  alternatives: number[];
  arrival_datetime: string | null;
  arrival_location: {
    latitude: number;
    longitude: number;
  } | null;
  arrival_title: string;
  base_services: { id: number; name: string }[];
  benefits: string;
  booked_independently: boolean | null;
  can_swap_alternatives: boolean | null;
  card_display: string;
  category: CategoryBE;
  city: CityBE | null;
  contact_details: string | null;
  curated_by: {
    avatar: string;
    first_name: string;
    last_name: string;
  };
  departure_title: string;
  description: string | null;
  drop_off_office_hours: string | null;
  duration: string | null;
  end_date: string | null;
  exact_time: boolean;
  featured_image: string | null;
  from_time: string | null;
  from_time_string: string | null;
  gallery: GalleryImageBE[];
  icon: string | null;
  id: number;
  internal_type: ExperienceType;
  is_alternative_for: number | null;
  location: Location | null;
  meeting_point: string | null;
  number_of_guests: number | null;
  number_of_nights: number | null;
  outbound_experience: number | null;
  parent: ExperienceParentBE | null;
  phone: string | null;
  pick_up_office_hours: string;
  provider: {
    first_name: string;
  } | null;
  return_experience: number | null;
  start_date: string | null;
  start_datetime: string | null;
  status: ExperienceStatus;
  style: ExperienceStyle | null;
  subcategory: SubcategoryBE;
  tags: Record<string, string | null | string[]>;
  title: string | null;
  total_price: string;
  total_price_booking_currency: string | null;
  travel_designer_notes: string;
  travelers?: string[];
  vehicle_type: string | null;
}
