<ng-container *transloco="let t; prefix: 'itinerary'">
  @if (isModal) {
    <div class="modal-backdrop" [mat-dialog-close]="true"></div>
    <div class="modal-container-surface" data-cy="quote-modal-container" cdkScrollable>
      <div class="quote modal-container" cdkScrollable>
        <button type="button" class="close" mat-dialog-close data-cy="modal-btn-close" aria-label="{{ t('close') }}">
          <mat-icon>close</mat-icon>
        </button>
        <ng-template [ngTemplateOutlet]="quote" />
      </div>
    </div>
  } @else {
    <div class="quote">
      <ng-template [ngTemplateOutlet]="quote" />
    </div>
  }
  <ng-template #quote>
    @if (quote$ | async; as quote) {
      <section class="content">
        <div [@slideInOutLeft]="showConfirmation ? 'hidden' : 'visible'">
          <ess-quote-view [quote]="quote" (selectedConfirmation)="openConfirmation($event)" />
        </div>
        <div [@slideInOutRight]="showConfirmation ? 'visible' : 'hidden'">
          <section class="back" [ngClass]="{isTab: !isModal}">
            <button
              type="button"
              [attr.aria-label]="t('backToPricingSummary')"
              class="button-content"
              (click)="closeConfirmation()"
              data-cy="back-pricing"
              ><i [innerHTML]="backIcon"></i> {{ t('pricingSummary') }}
            </button>
          </section>
          @if (showConfirmation && confirmationConfig) {
            <ess-confirmation
              [config]="confirmationConfig"
              [isModal]="false"
              (serviceSelectionSwap)="onServiceSelectionSwap()"
            />
          }
        </div>
      </section>
    } @else {
      <ess-load />
    }
  </ng-template>
</ng-container>
