import { Component, ElementRef, Inject, Input, OnInit, Optional } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Item, Quote } from '@shared/models/quote.model';
import { QuoteService } from '@features/quote/services/quote.service';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconService } from '@ess-front/shared';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ess-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  animations: [
    trigger('slideInOutLeft', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(0%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(-100%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOutRight', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(-100%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(0%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
  ],
})
export class QuoteComponent implements OnInit {
  @Input() hash: string | null = null;
  @Input() isModal = true;

  backIcon: SafeHtml | null;
  showConfirmation = false;
  quote$: Observable<Quote> | null = null;
  confirmationConfig: { hash: string; id: number } | undefined;

  constructor(
    private quoteService: QuoteService,
    private iconService: IconService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { hash: string },
    private el: ElementRef,
  ) {
    this.backIcon = iconService.getSVG('back');
    if (this.isModal && data && data.hash) {
      this.hash = data.hash;
      this.quote$ = this.quoteService.getQuote$(this.hash);
    }
  }

  ngOnInit(): void {
    if (!this.isModal) {
      this.quote$ = this.quoteService.getQuote$(this.hash ?? '');
    }
  }

  openConfirmation(item: Item) {
    this.confirmationConfig = { id: item.id, hash: this.hash ?? '' };
    this.showConfirmation = true;
    if (this.isModal) this.scrollModalTop();
  }

  closeConfirmation() {
    this.showConfirmation = false;
    if (this.isModal) this.scrollModalTop();
  }

  onServiceSelectionSwap() {
    if (this.hash) this.quote$ = this.quoteService.getQuote$(this.hash);
  }

  private scrollModalTop() {
    this.el.nativeElement.parentElement.scrollTo(0, 0);
  }
}
