import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment';
import { FlightInfoBE } from '@shared/models/itinerary/flight-information-be.model';
import { HttpListResponse } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class FlightInformationService {
  private apiURL = `${environment.apiEnv}api/v2/`;

  constructor(private httpClient: HttpClient) {}

  public getFlightInfo$(id: number, hash: string): Observable<FlightInfoBE[]> {
    const url = `${this.apiURL}bookings/${hash}/experiences/${id}/flights/`;
    return this.httpClient.get<HttpListResponse<FlightInfoBE>>(url).pipe(map(response => response.results));
  }
}
