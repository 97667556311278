import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  ExperienceCategory,
  ExperienceStatus,
  ExperienceStyle,
  ExperienceSubcategory,
  ExperienceType,
  LabeledValue,
  UtilsService,
} from '@ess-front/shared';
import { CarInfo } from '@shared/models/itinerary/car-information.model';
import { ExperienceBE, ExperienceParentType, GalleryImageBE } from '@shared/models/itinerary/experience-be.model';
import { ExperienceCardType } from '@shared/models/itinerary/experience-card-type.model';
import { Experience } from '@shared/models/itinerary/experience.model';
import { FlightInfoBE } from '@shared/models/itinerary/flight-information-be.model';
import { FlightInfo } from '@shared/models/itinerary/flight-information.model';
import { GalleryImage } from '@shared/models/itinerary/gallery-image.model';
import { Related } from '@shared/models/itinerary/related.model';
import { forkJoin, Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FlightInformationService } from './flight-information.service';
import { RelatedExperiencesService } from './related-experiences.service';
import { TranslationDomainService } from '@app/domain/translation-domain.service';

@Injectable({
  providedIn: 'root',
})
export class ExperienceService {
  private apiURL = `${environment.apiEnv}api/v2/bookings/`;

  constructor(
    private httpClient: HttpClient,
    private relatedExperiencesService: RelatedExperiencesService,
    private flightInformationService: FlightInformationService,
    private utilsService: UtilsService,
    private translationDomainService: TranslationDomainService,
  ) {}

  getExperience$(hash: string, id: number, date?: string): Observable<Experience> {
    const url = `${this.apiURL}${hash}/experiences/${id}`;
    return this.httpClient.get<ExperienceBE>(url).pipe(
      switchMap(experienceBE => {
        const alternatives$ = this.getAlternatives$(experienceBE, hash);
        const related$ = this.getRelated$(experienceBE);
        const flightInfo$ = this.getFlightInfo$(experienceBE, hash);
        return forkJoin([of(experienceBE), alternatives$, related$, flightInfo$]);
      }),
      map(([experienceBE, alternatives, related, flightInfo]) => {
        return this.transformIntoExperience(experienceBE, alternatives, related, flightInfo, date);
      }),
    );
  }

  setMainExperience$(hash: string, id: number): Observable<Record<string, never>> {
    const url = `${this.apiURL}${hash}/experiences/${id}/set_main/`;
    return this.httpClient.post<Record<string, never>>(url, {});
  }

  swapServiceSelected$(hash: string, experienceId: number, serviceId: number) {
    const url = `${this.apiURL}${hash}/experiences/${experienceId}/services/${serviceId}/set-base/`;
    return this.httpClient.patch(url, {});
  }

  getStaysAtHotel(type: ExperienceType, numberOfNights: number): boolean {
    return type === ExperienceType.ACCOMMODATION && numberOfNights > 0;
  }

  private transformIntoExperience(
    experienceBE: ExperienceBE,
    alternativeExperiences: Experience[],
    related: Related[],
    flightInfo: FlightInfoBE[],
    startDate?: string | null,
  ): Experience {
    return {
      alternatives: alternativeExperiences,
      benefits: experienceBE.benefits,
      bookedIndependently: experienceBE.booked_independently,
      canSwapAlternatives: experienceBE.can_swap_alternatives,
      carInfo: this.getCarInfo(experienceBE),
      cardType: this.getCardType(experienceBE),
      category: experienceBE.category.slug,
      city: experienceBE.city ? experienceBE.city.title : null,
      curatedBy: {
        avatar: experienceBE.curated_by.avatar,
        firstName: experienceBE.curated_by.first_name,
        lastName: experienceBE.curated_by.last_name,
      },
      description: experienceBE.description,
      travelDesignerNotes: experienceBE.travel_designer_notes,
      endDate: this.getEndDate(experienceBE, startDate),
      flightInfo: this.transformIntoFlight(flightInfo),
      fromTime: this.getFromTime(experienceBE),
      gallery: this.transformIntoGallery(experienceBE.gallery),
      hourTime: experienceBE.from_time,
      icon: experienceBE.icon,
      id: experienceBE.id,
      image: experienceBE.featured_image,
      location: experienceBE.location,
      numberNights: experienceBE.number_of_nights,
      numberGuests: experienceBE.number_of_guests,
      staysAtHotel: this.getStaysAtHotel(experienceBE.category.type, experienceBE.number_of_nights ?? 0),
      related: related,
      servicesSummary: this.getServicesSummary(
        experienceBE.base_services,
        experienceBE.total_price,
        experienceBE.total_price_booking_currency,
      ),
      startDate: this.getStartDate(experienceBE, startDate),
      startDatetime: this.getStartDateTime(experienceBE.start_datetime),
      status: experienceBE.status,
      style: experienceBE.style,
      subcategory: experienceBE.subcategory.slug,
      title: experienceBE.title,
      travelers: experienceBE.travelers,
      type: experienceBE.category.type,
      cardDisplay: experienceBE.card_display,
    };
  }

  private getStartDate(experienceBE: ExperienceBE, startDate?: string | null) {
    // For accommodations we need to fill start date due to not being filled by default
    if (
      experienceBE.category.type === ExperienceType.ACCOMMODATION &&
      startDate &&
      this.isBEStartDateValid(startDate)
    ) {
      return startDate;
    }
    // Check date has valid format to avoid parse errors caused by malformed dates as 666/01/01 from BE
    return experienceBE.start_date !== null && this.isBEStartDateValid(experienceBE.start_date)
      ? experienceBE.start_date
      : null;
  }

  private getStartDateTime(startDateTime: string | null): string | null {
    return startDateTime && this.isBEStartDateTimeValid(startDateTime) ? startDateTime : null;
  }

  // Check date has valid format with regex, expected format: 'yyyy-mm-dd'
  private isBEStartDateValid(startDate: string) {
    const regex =
      /^(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)$/;
    return regex.test(startDate);
  }

  // Check dateTime has valid format with regex, expected format: 'yyyy-mm-ddTHH:mm:ss'
  private isBEStartDateTimeValid(startDateTime: string) {
    const regex =
      /^(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)T(?:[01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return regex.test(startDateTime);
  }

  private getEndDate(experienceBE: ExperienceBE, startDate?: string | null) {
    // For accommodations we need to fill end date due to not being filled by default
    return experienceBE.category.type === ExperienceType.ACCOMMODATION && startDate
      ? this.addDays(startDate, experienceBE.number_of_nights || 0)
      : experienceBE.end_date;
  }

  private addDays(date: string, days: number): string {
    const newDate = new Date(new Date(date).getTime() + days * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    return formatDate(newDate, 'yyyy-MM-dd', 'en');
  }

  private getTags(tagsBE: Record<string, string | null | string[]>): string[] {
    return Object.values(tagsBE).filter(this.isNotNullOrEmptyArray).flat();
  }

  private isNotNullOrEmptyArray(value: string | null | string[]): value is string[] | string {
    return !!value && !(Array.isArray(value) && !value.length);
  }

  private getRelated$(experienceBE: ExperienceBE): Observable<Related[]> {
    if (this.isRelatedRequired(experienceBE)) {
      const lang = this.translationDomainService.getActiveLanguage();
      return this.relatedExperiencesService.getRelated$(experienceBE.parent?.id as number, lang);
    } else {
      return of([]);
    }
  }

  private getFlightInfo$(experienceBE: ExperienceBE, hash: string): Observable<FlightInfoBE[]> {
    if (
      experienceBE.category.slug === ExperienceCategory.AIR_TRANSPORTATION &&
      experienceBE.subcategory.slug === ExperienceSubcategory.FLIGHT
    ) {
      return this.flightInformationService.getFlightInfo$(experienceBE.id, hash);
    } else {
      return of([]);
    }
  }

  private isRelatedRequired(experienceBE: ExperienceBE): boolean {
    return !!(
      experienceBE.style === ExperienceStyle.SELF_EXPLORE &&
      experienceBE.parent?.type === ExperienceParentType.ExperienceModule &&
      experienceBE.parent?.id
    );
  }

  private getAlternatives$(experienceBE: ExperienceBE, hash: string): Observable<Experience[]> {
    if (experienceBE.alternatives.length && experienceBE.status !== ExperienceStatus.CONFIRMED) {
      const alternativesObsList = experienceBE.alternatives.map(alternativeId =>
        this.getExperience$(hash, alternativeId),
      );
      return zip(alternativesObsList);
    } else {
      return of([]);
    }
  }

  private getFromTime(experienceBE: ExperienceBE): string {
    const fromTimeString = experienceBE.from_time_string;
    const fromTime = experienceBE.from_time;

    if (experienceBE.exact_time) {
      const formattedFromTime = fromTime?.replace(/:\d{2}$/, '') ?? '';
      return [
        fromTimeString,
        this.utilsService.getFormattedTime(formattedFromTime, this.translationDomainService.getActiveLanguage()),
      ]
        .filter(Boolean)
        .join(' · ');
    } else {
      return fromTimeString || '';
    }
  }

  private getCardType(experience: ExperienceBE): ExperienceCardType {
    let cardType: ExperienceCardType = ExperienceCardType.GENERAL;
    const { category, subcategory, style } = experience;
    switch (category.slug) {
      case ExperienceCategory.AIR_TRANSPORTATION:
        cardType = ExperienceCardType.AIR_TRANSPORTATION;
        break;
      case ExperienceCategory.LAND_TRANSPORTATION:
        if (
          subcategory.slug === ExperienceSubcategory.CAR_RENTAL ||
          subcategory.slug === ExperienceSubcategory.LAND_TRANSPORTATION_CAR_RENTAL
        ) {
          cardType = ExperienceCardType.CAR_RENTAL;
        } else if (
          subcategory.slug === ExperienceSubcategory.TRANSFER ||
          subcategory.slug === ExperienceSubcategory.TRAIN_TRANSPORTATION
        ) {
          cardType = ExperienceCardType.TRANSFER;
        }
        break;
      case ExperienceCategory.EXPERIENCE:
        if (subcategory.slug === ExperienceSubcategory.CAR_RENTAL) {
          cardType = ExperienceCardType.CAR_RENTAL;
        }
        break;
      default:
        if (style === ExperienceStyle.SELF_EXPLORE) {
          cardType = ExperienceCardType.SELF_EXPLORE;
        } else {
          cardType = ExperienceCardType.GENERAL;
        }
    }
    return cardType;
  }

  private transformIntoFlight(flightInfoBE: FlightInfoBE[]): FlightInfo[] {
    return flightInfoBE.map(flight => ({
      arrivalAirport: flight.arrival_airport,
      arrivalDatetime: flight.arrival_datetime,
      arrivalTerminal: flight.arrival_terminal,
      cabin: flight.cabin,
      carrierAirline: flight.carrier_airline,
      carrierFlightNumber: flight.carrier_flight_number,
      departureAirport: flight.departure_airport,
      departureDatetime: flight.departure_datetime,
      departureTerminal: flight.departure_terminal,
      duration: flight.duration,
      layover: flight.layover,
      seats: flight.seats,
    }));
  }

  private getCarInfo(experienceBE: ExperienceBE): CarInfo {
    return {
      arrivalDatetime: experienceBE.arrival_datetime,
      arrivalLocation: {
        latitude: experienceBE.arrival_location?.latitude,
        longitude: experienceBE.arrival_location?.longitude,
      },
      arrivalTitle: experienceBE.arrival_title,
      contactDetails: experienceBE.contact_details,
      departureTitle: experienceBE.departure_title,
      dropOffOfficeHours: experienceBE.drop_off_office_hours,
      duration: experienceBE.duration,
      meetingPoint: experienceBE.meeting_point,
      phone: experienceBE.phone,
      pickUpOfficeHours: experienceBE.pick_up_office_hours,
      provider: {
        firstName: experienceBE.provider?.first_name,
      },
      vehicleType: experienceBE.vehicle_type,
    };
  }

  private transformIntoGallery(galleryBE: GalleryImageBE[]): GalleryImage[] {
    return galleryBE.map(image => ({
      localSrc: image.local_src,
      title: image.title,
      description: image.description,
    }));
  }

  private getServicesSummary(
    baseServices: { id: number; name: string }[],
    totalPrice: string,
    totalPriceBookingCurrency: string | null,
  ): LabeledValue<string | null> | null {
    return baseServices.length
      ? {
          label: baseServices.map(service => service.name).join(' + '),
          value: this.priceIfNotZero(totalPriceBookingCurrency ?? totalPrice),
        }
      : null;
  }

  private priceIfNotZero(price: string): string | null {
    const zeroPriceWithAnyCurrencyRegex = /\p{Sc}0\.00/u;
    return price.match(zeroPriceWithAnyCurrencyRegex) ? null : price;
  }
}
