import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TripService } from '@api/trip.service';
import { ExperienceService } from '@app/api/experience.service';
import { SnackBarService } from '@app/core/services/snack-bar/snack-bar.service';
import { QuoteComponent } from '@features/quote/quote/quote.component';
import { Experience } from '@shared/models/itinerary/experience.model';
import { Trip } from '@shared/models/itinerary/trip.model';
import { StoreService } from '@store/store.service';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { TranslationDomainService } from './translation-domain.service';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Injectable({
  providedIn: 'root',
})
export class ItineraryDomainService {
  constructor(
    private experienceService: ExperienceService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private store: StoreService,
    private tripService: TripService,
    private translationDomainService: TranslationDomainService,
  ) {}

  public seePricingSummary(hash: string): void {
    if (hash) {
      this.dialog.open(QuoteComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100vh',
        width: '100vw',
        data: {
          hash: hash,
        },
      });
    }
  }

  switchExperienceForAlternative$(alternative: Experience): Observable<Experience | null> {
    const { trip, days } = this.store.getItineraryState();
    const alternativeId = alternative.id;

    const experience = days
      .map(day => day.experiences)
      .flat()
      .find(experience => experience.alternatives.some(alternative => alternative.id === alternativeId));

    if (trip && experience) {
      this.store.setIsLoading(true);
      return this.experienceService.setMainExperience$(trip.hash, alternative.id).pipe(
        switchMap(() =>
          combineLatest([
            this.tripService.getTripQuote$(trip.hash, trip.typology, trip.totalPrice),
            this.experienceService.getExperience$(trip.hash, alternative.id),
          ]),
        ),
        tap(([quote, alternative]) => {
          if (quote) this.store.setQuote(quote);
          this.store.updateExperience(experience.id, alternative);
        }),
        switchMap(([, alternative]) => of(alternative)),
        tap(() => {
          this.store.setIsLoading(false);
          const snackbarMessage = this.translationDomainService.translate(marker('snackbar.itineraryUpdateSuccess'));
          this.snackBarService.open(snackbarMessage);
        }),
        catchError(() => {
          this.store.setIsLoading(false);
          const snackbarMessage = this.translationDomainService.translate(marker('errors.genericRequest'));
          this.snackBarService.open(snackbarMessage);
          return of(null);
        }),
      );
    } else {
      return of(null);
    }
  }

  swapServiceSelected$(hash: string, experienceId: number, serviceId: number) {
    this.store.setIsLoading(true);
    const { trip } = this.store.getItineraryState();
    return this.experienceService.swapServiceSelected$(hash, experienceId, serviceId).pipe(
      switchMap(() => {
        const quote$ = trip ? this.tripService.getTripQuote$(trip.hash, trip.typology, trip.totalPrice) : of(null);
        return forkJoin([quote$, this.experienceService.getExperience$(hash, experienceId)]);
      }),
      tap(([quote, experience]) => {
        quote && this.store.setQuote(quote);
        this.store.updateExperience(experienceId, experience);
        const snackbarMessage = this.translationDomainService.translate(marker('snackbar.itineraryUpdateSuccess'));
        this.snackBarService.open(snackbarMessage);
      }),
      finalize(() => this.store.setIsLoading(false)),
      catchError(() => {
        const snackbarMessage = this.translationDomainService.translate(marker('errors.genericRequest'));
        this.snackBarService.open(snackbarMessage);
        return of(null);
      }),
    );
  }

  updateTrip$(hash: string): Observable<Trip> {
    return this.tripService.getTrip$(hash).pipe(tap(trip => this.store.setTrip(trip)));
  }
}
